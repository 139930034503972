import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Images = () => {
    const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "01.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      placeholderImage02: file(relativePath: { eq: "02.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      placeholderImage03: file(relativePath: { eq: "03.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      placeholderImage04: file(relativePath: { eq: "04.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      placeholderImage05: file(relativePath: { eq: "05.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      placeholderImage06: file(relativePath: { eq: "06.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      placeholderImage07: file(relativePath: { eq: "07.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      placeholderImage08: file(relativePath: { eq: "08.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      placeholderImage09: file(relativePath: { eq: "09.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      placeholderImage10: file(relativePath: { eq: "10.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      placeholderImage11: file(relativePath: { eq: "11.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      placeholderImage12: file(relativePath: { eq: "12.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      placeholderImage13: file(relativePath: { eq: "13.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      placeholderImage14: file(relativePath: { eq: "14.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      placeholderImage15: file(relativePath: { eq: "15.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      placeholderImage16: file(relativePath: { eq: "16.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      placeholderImage17: file(relativePath: { eq: "17.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      placeholderImage18: file(relativePath: { eq: "18.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      placeholderImage19: file(relativePath: { eq: "19.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
       placeholderImage20: file(relativePath: { eq: "20.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
    return(
        <section className="images">
                <>
                <Img fluid={data.placeholderImage.childImageSharp.fluid} className="img" />
                <Img fluid={data.placeholderImage02.childImageSharp.fluid} className="img" />
                <Img fluid={data.placeholderImage03.childImageSharp.fluid} className="img" />
                <Img fluid={data.placeholderImage04.childImageSharp.fluid} className="img" />
                <Img fluid={data.placeholderImage05.childImageSharp.fluid} className="img" />
                <Img fluid={data.placeholderImage06.childImageSharp.fluid} className="img" />
                <Img fluid={data.placeholderImage07.childImageSharp.fluid} className="img" />
                <Img fluid={data.placeholderImage08.childImageSharp.fluid} className="img" />
                <Img fluid={data.placeholderImage09.childImageSharp.fluid} className="img" />
                <Img fluid={data.placeholderImage10.childImageSharp.fluid} className="img" />
                <Img fluid={data.placeholderImage11.childImageSharp.fluid} className="img" />
                <Img fluid={data.placeholderImage12.childImageSharp.fluid} className="img" />
                <Img fluid={data.placeholderImage13.childImageSharp.fluid} className="img" />
                <Img fluid={data.placeholderImage14.childImageSharp.fluid} className="img" />
                <Img fluid={data.placeholderImage19.childImageSharp.fluid} className="img" />
                <Img fluid={data.placeholderImage20.childImageSharp.fluid} className="img" />
                <Img fluid={data.placeholderImage15.childImageSharp.fluid} className="img" />
                <Img fluid={data.placeholderImage16.childImageSharp.fluid} className="img" />
                <Img fluid={data.placeholderImage17.childImageSharp.fluid} className="img" />
                <Img fluid={data.placeholderImage18.childImageSharp.fluid} className="img" />
                </>
        </section>
    )
}

export default Images