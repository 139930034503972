import React from "react"
import Images from '../components/images'
import Layout from "../components/layout"

import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />

   <Images />
    
  </Layout>
)

export default IndexPage
